import { map, prop, find, pathOr, propEq } from 'ramda';
import sumStudentCount from '../sumStudentCount';

const formatIndicators = (
  { studentCount, participationRate, reservePosition },
  sector
) => {
  const participationRateKey = sector === 'PO' ? 'SO' : 'VSO';
  return {
    studentCount: sumStudentCount(studentCount._2018_2019),
    participationRate: pathOr(
      0,
      ['_2018_2019', participationRateKey],
      participationRate
    ),
    reservePosition: pathOr(0, ['_2019', 'resistance'], reservePosition),
  };
};

const getCounts = ({ schools, schoolboards, municipalities }) => ({
  schools: schools.length,
  schoolboards: schoolboards.length,
  municipalities: municipalities.length,
});

const formatPartnershipsForMap = ({
  allDatoCmsPartnership,
  allPartnership,
}) => {
  const datoNodes = map(prop('node'), allDatoCmsPartnership.edges);
  const rethinkNodes = map(prop('node'), allPartnership.edges);

  const combinedNodes = map(({ slug, indicators, sector, ...node }) => {
    const current = find(propEq('slug', slug), datoNodes);
    const formattedIndicators = formatIndicators(indicators, sector);
    const counts = getCounts(node);

    return {
      sector,
      shortAddress: `${current.datoZipcode || node.zipcode}, ${
        current.datoCity || node.city
      }`,
      ...current,
      ...node,
      address: current.datoAddress
        ? `${current.datoAddress} ${current.datoAddressHousenumber} ${current.datoAddressAddition}`
        : `${node.street} ${node.houseNumber}${node.houseNumberAddition}`,
      zipcode: current.datoZipcode || node.zipcode,
      city: current.datoCity || node.city,
      ...formattedIndicators,
      ...counts,
    };
  }, rethinkNodes);

  return combinedNodes;
};

export default formatPartnershipsForMap;
